<template>
	<div class="testimonial-carousel">
		<h2>Testimonials</h2>
		<div class="slider">
			<div
				v-for="(testimonial, index) in blockData.testimonials"
				:key="index"
				class="testimonial"
			>
				<div
					class="truncate-text"
					:style="{
						height: expandedIndexes.includes(index)
							? 'auto'
							: truncatedHeight
					}"
				>
					<p
						class="testimonial-text-blur"
						v-if="!expandedIndexes.includes(index)"
					>
						Relationship Ready is a profound and heartwarming
						exploration of love, relationships, and self-discovery.
						Lila's vulnerability and honesty make this book a
						relatable and transformative read for anyone seeking
						deeper connections, both with others and within
						themselves. Her personal stories and client experiences
						shine a light on how our thoughts shape our
						relationships, while offering hope and practical
						insights for navigating love with more ease and clarity.
						What I found most beautiful is the gentle reminder that
						love starts with the relationship we have with
						ourselves. Through her accessible and compassionate
						writing, Lila invites readers to reflect on their own
						journeys, while illustrating that true change comes from
						within. This book is not just about finding love; it's
						about discovering the wisdom and love that have been
						with us all along. Relationship Ready is an inspiring
						guide for anyone wanting to experience more joy,
						connection, and fulfillment in their relationships. It
						is filled with grace, insight, and practical wisdom that
						will resonate with readers long after they finish the
						last page.
					</p>
					<span v-if="!expandedIndexes.includes(index)">
						{{ truncateText(testimonial.text, 195) }}
					</span>
					<div v-else>
						<p class="testimonial-text">
							{{ testimonial.text }}
						</p>
						<div class="testimonial-meta">
							<h4 class="testimonial-name">
								{{ testimonial.name }}
							</h4>
							<p class="testimonial-role">
								{{ testimonial.role }}
							</p>
						</div>
					</div>
					<button
						v-if="!expandedIndexes.includes(index)"
						type="button"
						@click="toggleTextVisibility(index)"
					>
						Read More
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default {
	name: "BookTestimonialCarousel",
	props: {
		blockData: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			expandedIndexes: [], // Array per tracciare i testimonial espansi
			truncatedHeight: "auto" // Imposta l'altezza iniziale
		};
	},
	mounted() {
		this.initializeSlider();
	},
	methods: {
		// Inizializza il carousel
		initializeSlider() {
			$(this.$el).find(".slider").slick({
				dots: true,
				arrows: false,
				infinite: true,
				speed: 500,
				fade: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				adaptiveHeight: true
			});
		},

		// Troncamento del testo
		truncateText(text, maxLength) {
			if (text.length > maxLength) {
				return text.slice(0, maxLength) + "...";
			}
			return text;
		},

		// Gestisce la visibilità del testo espanso
		toggleTextVisibility(index) {
			// Gestisce il toggle del testo espanso
			if (this.expandedIndexes.includes(index)) {
				// Rimuove l'indice se già espanso
				this.expandedIndexes = this.expandedIndexes.filter(
					(i) => i !== index
				);
			} else {
				// Aggiunge l'indice se non espanso
				this.expandedIndexes.push(index);
			}

			// Ricalcola la posizione e l'altezza dello slider dopo aver espanso o compresso il testo
			this.$nextTick(() => {
				$(this.$el).find(".slider").slick("setPosition");
			});
		}
	},
	beforeUnmount() {
		$(this.$el).find(".slider").slick("unslick");
	}
};
</script>

<style scoped>
.testimonial-carousel {
	padding-top: 8vw;
	width: 100vw;
	background: #f7f5f3;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-bottom: 0vw;
}

.testimonial-carousel h2 {
	position: relative;
	text-align: center;
	font-size: 3vw;
	display: inline-block;
	margin: 0;
	margin-bottom: 3vw;
}

.slider {
	width: 75vw;
	margin-inline: auto;
}

.testimonial {
	padding: 0;
	text-align: center;
}

.testimonial-text {
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1.2vw;
	font-family: DM sans;
	color: rgb(84 74 63 / 100%);
	padding: 2vw 1vw;
}

.testimonial-meta {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 1vw;
}

.testimonial-name {
	margin: 1.8vw 0 0;
	font-size: 1.6vw;
	color: rgb(84 74 63 / 100%);
}

.testimonial-role {
	color: rgb(84 74 63 / 100%);
	font-size: 1vw;
	margin: 0;
	max-width: 15vw;
	margin-inline: auto;
	font-family: DM sans;
}

.truncate-text {
	max-width: 100vw;
	width: 100%;
	transition: height 0.3s ease;
	overflow: hidden;
}

.testimonial-text-blur {
	position: absolute;
	width: 100%;
	text-align: left;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 1.2vw;
	font-family: DM sans;
	color: rgb(84 74 63 / 50%);
	filter: blur(6px);
	padding: 2vw 0vw;
	display: none;
}

.truncate-text span {
	position: relative;
	display: block;
	max-width: 100vw;
	text-align: left;
	font-size: 2.5vw;
	line-height: 3vw;
}

.truncate-text span::before,
.truncate-text span::after {
	content: ' " ';
	display: inline-block;
	width: auto;
	height: auto;
	line-height: 1;
	max-height: 9px;
}

.truncate-text button {
	position: relative;
	margin-top: 3vw;
	left: 0;
	width: 18vw;
	height: 4.5vw;
	display: block;
	text-transform: uppercase;
	background: none;
	border: 0.2vw solid #d4c09e;
	cursor: pointer;
	font-size: 0.9vw;
	color: #544a3f;
	padding: 0.45vw;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
}

@media (max-aspect-ratio: 200 / 200) {
	.testimonial-carousel {
		padding-bottom: 20vw;
	}

	.slider {
		width: 88vw;
	}
	.testimonial-carousel h2 {
		font-size: 8vw;
		margin-bottom: 5vw;
	}
	.testimonial-text {
		font-size: 3.8vw;
	}
	.testimonial-meta {
		margin-top: 3vw;
		margin-bottom: 3vw;
	}
	.testimonial-name {
		font-size: 5vw;
	}

	.testimonial-role {
		font-size: 3vw;
		max-width: 37vw;
	}
	.truncate-text span {
		max-width: 78vw;
		font-size: 5.8vw;
		line-height: 6.8vw;
	}
	.testimonial-text-blur {
		font-size: 2.7vw;
	}
	.truncate-text button {
		width: 54vw;
		font-size: 2.5vw;
		height: 15.5vw;
		border: 0.5vw solid #d4c09e;
		margin-top: 8vw;
	}
	.slider .slick-dots {
		bottom: -50px !important;
	}
}
</style>

<style>
@media (max-aspect-ratio: 200 / 200) {
	.slider .slick-dots {
		bottom: -50px !important;
	}
}
</style>
